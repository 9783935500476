import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, IconButton, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { CloseSharp } from 'react-ionicons';
import { postApiPrivate } from '../../../api';
import swal from 'sweetalert';
import { langs } from './constants';
import './DynamicContent.css'

export default function DynamicContentModal({ open, onClose, LangName, setLangName, currentContentItem, onSave, match }) {
    const [content, setContent] = useState(currentContentItem || {
        language: currentContentItem?.language || 'en',
    });
    const [hasChanges, setHasChanges] = useState(false);

    console.log("yy:content", content)
    console.log("yy:currentContentItem", currentContentItem)

    useEffect(() => {
        setHasChanges(false);
    }, [open, currentContentItem]);

    const checkForChanges = (updatedContent) => {
        if (!currentContentItem) {
            setHasChanges(
                updatedContent.language &&
                updatedContent.type &&
                updatedContent.title &&
                updatedContent.description
            );
        } else {
            setHasChanges(
                updatedContent.title !== currentContentItem.title ||
                updatedContent.description !== currentContentItem.description
            );
        }
    };

    const handleSave = async () => {
        if (!hasChanges) return;

        const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;
        let payload = {
            locationId: match.params.id,
            ...content
        }
        if(currentContentItem) {
            payload = {
                ...payload,
                ...currentContentItem,
                title: content.title || currentContentItem.title,
                description: content.description || currentContentItem.description
            }
        }
        const response = await postApiPrivate('/admin/dynamic-contents', payload, JWT);

        if(response.error?.response?.status === 400) {
            swal('Error', 'Please fill all the fields', 'error');
            return
        }

        onSave && onSave();
        onClose();
    };

return (
    <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="dynamic-content-modal-title"
        aria-describedby="dynamic-content-modal-description"
    >
        <Box sx={Styles.modalContainer}>
            <Box sx={Styles.modalHeader}>
                <Typography id="dynamic-content-modal-title" variant="h6" component="h2">
                    {currentContentItem ? 'Edit Item' : 'Add New Item'}
                </Typography>
                <IconButton onClick={onClose} size="small">
                    <CloseSharp style={{ cursor: 'pointer' }} />
                </IconButton>
            </Box>

            <Box sx={Styles.formContainer}>
                {  !currentContentItem &&
                    <>
                    <FormControl fullWidth sx={Styles.formField}>
                        <InputLabel id="category-select-label">Language</InputLabel>
                        <Select
                            labelId="category-select-label"
                            id="category-select"
                            defaultValue={content.language}
                            onChange={(e) => {
                            const updatedContent = { ...content, language: e.target.value };
                            setContent(updatedContent);
                            checkForChanges(updatedContent);
                            }}
                            label="Language"
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    {selected === 'en' && <img src="/assets/languages/en.png" style={{ width: 20, height: 20, borderRadius: '50%' }} />}
                                    {selected === 'it' && <img src="/assets/languages/it.png" style={{ width: 20, height: 20, borderRadius: '50%' }} />}
                                    {selected === 'fr' && <img src="/assets/languages/fr.png" style={{ width: 20, height: 20, borderRadius: '50%' }} />}
                                    {selected === 'es' && <img src="/assets/languages/es.png" style={{ width: 20, height: 20, borderRadius: '50%' }} />}
                                    {selected === 'de' && <img src="/assets/languages/de.png" style={{ width: 20, height: 20, borderRadius: '50%' }} />}
                                    <span>{langs[selected]}</span>
                                </Box>
                            )}
                        >
                            <MenuItem value="en" sx={Styles.menuItem}>
                                <img src="/assets/languages/en.png"
                                    style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer' }}
                                />
                                <span>English</span>
                            </MenuItem>
                            <MenuItem value="it" sx={Styles.menuItem}>
                                <img src="/assets/languages/it.png"
                                    style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer' }}
                                />
                                <span>Italian</span>
                            </MenuItem>
                            <MenuItem value="fr" sx={Styles.menuItem}>
                                <img
                                    src="/assets/languages/fr.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer'}}
                                />
                                <span>French</span>
                            </MenuItem>
                            <MenuItem value="es" sx={Styles.menuItem}>
                                <img
                                    src="/assets/languages/es.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer'}}
                                />
                                <span>Spanish</span>
                            </MenuItem>
                            <MenuItem value="de" sx={Styles.menuItem}>
                                <img
                                    src="/assets/languages/de.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer'}}
                                />
                                <span>German</span>
                            </MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={Styles.formField}>
                        <InputLabel id="type-select-label">Type</InputLabel>
                        <Select
                            labelId="type-select-label"
                            id="type-select"
                            defaultValue={currentContentItem?.type || 'type'}
                            label="Type"
                            onChange={(e) => {
                                const updatedContent = { ...content, type: e.target.value };
                                setContent(updatedContent);
                                checkForChanges(updatedContent);
                            }}
                        >
                            <MenuItem value="explore">Explore</MenuItem>
                            <MenuItem value="faq">Faq</MenuItem>
                            <MenuItem value="product_type">Product type</MenuItem>
                        </Select>
                    </FormControl>
                </>
                }

                <TextField
                    fullWidth
                    label="Title"
                    placeholder="Enter title"
                    defaultValue={currentContentItem?.title || ''}
                    sx={Styles.formField}
                    className='no-border-input'
                    onChange={(e) => {
                        const updatedContent = { ...content, title: e.target.value };
                        setContent(updatedContent);
                        checkForChanges(updatedContent);
                    }}
                />

                <TextField
                    fullWidth
                    label="Description"
                    placeholder="Enter description"
                    defaultValue={currentContentItem?.description || ''}
                    sx={Styles.formField}
                    className='no-border-input'
                    onChange={(e) => {
                        const updatedContent = { ...content, description: e.target.value };
                        setContent(updatedContent);
                        checkForChanges(updatedContent);
                    }}
                />
            </Box>

            <div style={Styles.buttonsContainer}>
                <button
                    className='btn secondary'
                    style={{
                        ...Styles.buttonStyle,
                        backgroundColor: '#f5f5f5',
                        color: '#333'
                    }}
                    onClick={onClose}
                >
                    Cancel
                </button>
                <button
                    className='btn primary'
                    style={{
                        ...Styles.buttonStyle,
                        opacity: hasChanges ? 1 : 0.5,
                        cursor: hasChanges ? 'pointer' : 'not-allowed'
                    }}
                    onClick={handleSave}
                    disabled={!hasChanges}
                >
                    Save
                </button>
            </div>
        </Box>
    </Modal>
  );
}

const Styles = {
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    p: 3
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 10,
    width: '100%'
  },
  buttonStyle: {
    flex: 1,
    padding: '8px 16px',
    minWidth: '120px',
    width: '50%'
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 2
  },
  formContainer: {
    mb: 2
  },
  formField: {
    mb: 2,
    border: 'none',
    outline: 'none'
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 1
  },
  saveButton: {
    mt: 1
  }
};