import axios from 'axios';

const API_SIMPLE = 'https://api.babonbo.net'; //LIVE

//const API_SIMPLE = 'http://localhost:3003'; //CURRENT

//const API_SIMPLE = 'https://babonbo.herokuapp.com';

//const API_SIMPLE = 'http://172.20.10.2:5000'; //HOTSPOT

//const API_SIMPLE = 'http://192.168.178.20:5000'; //HOME
//const API_SIMPLE = 'http://192.168.1.204:5000'; //BAR PASTICERIA
//const API_SIMPLE = 'http://192.168.100.2:5000' //TIJUANA;
//const API_SIMPLE = 'http://172.19.248.186:20:5000' //LOCAL;
//const API_SIMPLE = 'http://172.16.27.125:5000' //ANTALYA;
//const API_SIMPLE = 'http://192.168.178.21:5000' //MONTEGRAPPA

//const API_SIMPLE = 'http://192.168.1.109:5000'; //ZAHRA / MARRAKESH

//const API_SIMPLE = 'http://192.168.1.153:5000' //BELLA MARIA / CASABLANCA

//const API_SIMPLE = 'http://192.168.1.22:5000' //MANSORIA

//const API_SIMPLE = 'http://192.168.1.109:5000' //GATOS CASABLANCA

//const API_SIMPLE = 'http://192.168.11.189:5000'// FIBRA MARRAKESH

//const API_SIMPLE = 'http://192.168.1.176:5000'// LARENA CASABLANCA

//const API_SIMPLE = 'http://172.16.75.14:5000'// KOPERATIF HOUSE / ISTANBUL

//const API_SIMPLE = 'http://192.168.1.4:5000' // MAJO HOUSE

//const API_SIMPLE = 'http://192.168.1.193:5000'; //GALTA TOWER

//const API_SIMPLE = 'http://192.168.2.143:5000'; //AWAY SUITES TAKSIM

//const API_SIMPLE = 'http://192.168.1.147:5000'; //MEDICASIMPLE

//const API_SIMPLE = 'http://192.168.0.111:5000'; //JANTI COFFEE

//const API_SIMPLE = 'http://192.168.0.15:5000'; //EREN'S HOUSE

//const API_SIMPLE = 'http://192.168.1.127:5000'; //DUYGU'S HOUSE

//const API_SIMPLE = 'http://10.35.35.112:5000'; //AURA TERAS

//const API_SIMPLE = 'http://192.168.43.70:5000'; //BABONBO TURKISH HS

//const API_SIMPLE = 'http://172.20.10.7:5000'; //AHMED HS IZMIR

//const API_SIMPLE = 'http://172.20.10.9:5000'; //EREN HS

//const API_SIMPLE = 'http://192.168.1.132:5000'; //AURA BLOCK C

//const API_SIMPLE = 'http://192.168.0.181:5000'; //AILED House

//const API_SIMPLE = 'http://192.168.1.67:5000'; //CESAR HOUSE

//const API_SIMPLE = 'http://192.168.0.181:5000'; //DLA

//const API_SIMPLE = 'http://192.168.15.238:5000'; //Brew Mission Beach

//const API_SIMPLE = 'http://192.168.1.64:5000'; //Alan House

//const API_SIMPLE = 'http://192.168.0.13:5000'; //Amy's House
//const API_SIMPLE = 'http://192.168.1.71:5000'; //Hugo's House
//const API_SIMPLE = 'http://192.168.1.65:5000'; //Café Mo TJ
//const API_SIMPLE = 'http://192.168.1.116:5000'; //IST
//const API_SIMPLE = 'http://192.168.0.18:5000'; //Eren House
//const API_SIMPLE = 'http://192.168.1.100:5000'; //BEBEK MY HOUSE
//const API_SIMPLE = 'http://172.20.10.2:5000'; //HOTSPOT
//const API_SIMPLE = 'http://192.168.0.117:5000'; //Zahra
//const API_SIMPLE = 'http://192.168.1.59:5000'; //Zahra Voisine
//const API_SIMPLE = 'http://192.168.0.120:5000'; //Cafe TPLINK Marrakesh
//const API_SIMPLE = 'http://192.168.20.126:5000'; //Africana 2022

//const API_SIMPLE = 'http://192.168.0.54:5000'; //GrandPa Qods INWI
//const API_SIMPLE = 'http://192.168.0.20:5000'; //EREN 2022

//const API_SIMPLE = 'http://172.16.20.165:5000'; //NETTE DUBAI

const API_ROOT = API_SIMPLE + '/api/v1';

const getApiPublic = async (API_URL) => {
  var API_REQUEST = API_ROOT + API_URL;

  try {
    var DATA = await axios.get(API_REQUEST);
    var DATA_RESPONSE = DATA.data;

    console.log('API GET PUBLIC SUCCESS ===> ', API_REQUEST);

    return DATA_RESPONSE;
  } catch (e) {
    console.log('API GET PUBLIC ERROR ===> ', API_REQUEST);
    return { error: e };
  }
};

const getApiPrivate = async (API_URL, JWT) => {
  var API_REQUEST = API_ROOT + API_URL;

  try {
    var DATA = await axios.get(API_REQUEST, { headers: { authorization: JWT } });
    var DATA_RESPONSE = DATA.data;

    console.log('API GET PRIVATE SUCCESS ===> ', API_REQUEST);

    return DATA_RESPONSE;
  } catch (e) {
    console.log('API GET PRIVATE ERROR ===> ', API_REQUEST);
    return { error: e };
  }
};

const postApiPublic = async (API_URL, FORM) => {
  var API_REQUEST = API_ROOT + API_URL;

  try {
    var DATA = await axios.post(API_REQUEST, FORM);
    var DATA_RESPONSE = DATA.data;

    console.log('API POST PUBLIC SUCESS ===> ', API_REQUEST);

    return DATA_RESPONSE;
  } catch (e) {
    console.log('API POST PUBLIC ERROR ===> ', API_REQUEST);
    return { error: e };
  }
};

const postApiPrivate = async (API_URL, FORM, JWT) => {
  var API_REQUEST = API_ROOT + API_URL;

  try {
    var DATA = await axios.post(API_REQUEST, FORM, { headers: { authorization: JWT } });
    var DATA_RESPONSE = DATA.data;

    console.log('API POST PRIVATE DATA ===> ', API_REQUEST);

    return DATA_RESPONSE;
  } catch (e) {
    console.log('API POST PRIVATE ERROR ===> ', API_REQUEST);
    return { error: e };
  }
};

const deleteApiPrivate = async (API_URL, JWT) => {
  var API_REQUEST = API_ROOT + API_URL;

  try {
    var DATA = await axios.delete(API_REQUEST, { headers: { authorization: JWT } });
    var DATA_RESPONSE = DATA.data;

    console.log('API DELETE PRIVATE DATA ===> ', API_REQUEST);

    return DATA_RESPONSE;
  } catch (e) {
    console.log('API DELETE PRIVATE ERROR ===> ', API_REQUEST);
    return { error: e };
  }
};

export { API_ROOT, API_SIMPLE, getApiPrivate, getApiPublic, postApiPrivate, postApiPublic, deleteApiPrivate };
