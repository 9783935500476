import React, { useState, useEffect } from 'react'
import DynamicContentModal from './DynamicContentModal'
import { MenuItem, Select, FormControl, InputLabel, Box } from '@mui/material';
import swal from 'sweetalert';
import { deleteApiPrivate } from '../../../api';
import { langs } from './constants';

export default function DynamicContent({ LangName, match, PUBLIC_DYNAMIC_CONTENTS, onFetchDynamicContent }) {
	const [isModalOpen, setModalOpen] = useState(false);
	const [currentContentItem, setCurrentContentItem] = useState(null);
	const [selectedLanguage, setSelectedLanguage] = useState('en');
	const [selectedType, setSelectedType] = useState('all');
	const [isMobile, setIsMobile] = useState(false);
	const [filteredContents, setFilteredContents] = useState([]);

	useEffect(() => {
		const newFilteredContents = PUBLIC_DYNAMIC_CONTENTS.filter(item => {
			const languageMatch = selectedLanguage === 'all' || item.language === selectedLanguage;
			const typeMatch = selectedType === 'all' || item.type === selectedType;
			return languageMatch && typeMatch;
		});

		setFilteredContents(newFilteredContents);
	}, [PUBLIC_DYNAMIC_CONTENTS, selectedLanguage, selectedType])

	const handleDelete = async (contentId) => {
		const JWT = (typeof global.window !== 'undefined') ? localStorage.getItem('jwt_token') : null;

		const response = await deleteApiPrivate(`/admin/dynamic-contents/${contentId}`, JWT);

		if(response.error?.response?.status === 404) {
			swal('Error', 'Content not found', 'error');
			return
		}

		if(response.error?.response?.status === 401) {
			swal('Error', 'Unauthorized', 'error');
			return
		}

		await onFetchDynamicContent(match.params.id);
		swal('Success', 'Content deleted successfully', 'success')
	}

	useEffect(() => {
		const checkIfMobile = () => {
			setIsMobile(window.innerWidth < 600);
		};

		checkIfMobile();

		window.addEventListener('resize', checkIfMobile);

		return () => window.removeEventListener('resize', checkIfMobile);
	}, []);

	useEffect(() => {
		console.log("yy:currentContentItem", currentContentItem)
	}, [currentContentItem])

	return (
    <>
    <div style={{
		...Styles.headerContainer,
		...(isMobile && Styles.headerContainerMobile)
	}}>
        <div style={{
			...Styles.selectorsContainer,
			...(isMobile && Styles.selectorsContainerMobile)
		}}>
			<FormControl
				style={{ width: '100%' }}
			>
                <InputLabel id="language-select-label">Language</InputLabel>
                <Select
                    labelId="language-select-label"
                    id="language-select"
                    value={selectedLanguage}
                    label="Language"
                    onChange={(e) => {
                        setSelectedLanguage(e.target.value);
                    }}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {selected === 'all' && <span>All</span>}
                            {selected === 'en' && <img src="/assets/languages/en.png" style={{ width: 20, height: 20, borderRadius: '50%' }} />}
                            {selected === 'it' && <img src="/assets/languages/it.png" style={{ width: 20, height: 20, borderRadius: '50%' }} />}
                            {selected === 'fr' && <img src="/assets/languages/fr.png" style={{ width: 20, height: 20, borderRadius: '50%' }} />}
                            {selected === 'es' && <img src="/assets/languages/es.png" style={{ width: 20, height: 20, borderRadius: '50%' }} />}
                            {selected === 'de' && <img src="/assets/languages/de.png" style={{ width: 20, height: 20, borderRadius: '50%' }} />}
                            <span>{langs[selected]}</span>
                        </Box>
                    )}
                >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="en" sx={Styles.menuItem}>
                        <img src="/assets/languages/en.png"
                            style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'en' ? 1 : 0.5) }} 
                        />
                        <span>English</span>
                    </MenuItem>
                    <MenuItem value="it" sx={Styles.menuItem}>
                        <img src="/assets/languages/it.png"
                            style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'en' ? 1 : 0.5) }}
                        />
                        <span>Italian</span>
                    </MenuItem>
                    <MenuItem value="fr" sx={Styles.menuItem}>
                        <img
                            src="/assets/languages/fr.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'en' ? 1 : 0.5) }} 
                        />
                        <span>French</span>
                    </MenuItem>
                    <MenuItem value="es" sx={Styles.menuItem}>
                        <img
                            src="/assets/languages/es.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'en' ? 1 : 0.5) }} 
                        />
                        <span>Spanish</span>
                    </MenuItem>
                    <MenuItem value="de" sx={Styles.menuItem}>
                        <img
                            src="/assets/languages/de.png" style={{ width: 20, height: 20, borderRadius: '50%', marginLeft: 5, cursor: 'pointer', opacity: (LangName === 'en' ? 1 : 0.5) }} 
                        />
                        <span>German</span>
                    </MenuItem>
                </Select>
            </FormControl>

            <FormControl style={{ width: '100%' }}>
                <InputLabel id="type-select-label">Type</InputLabel>
                <Select
                    labelId="type-select-label"
                    id="type-select"
                    value={selectedType}
                    label="Type"
                    onChange={(e) => {
                        setSelectedType(e.target.value);
                    }}
                >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="explore">Explore</MenuItem>
                    <MenuItem value="faq">Faq</MenuItem>
                    <MenuItem value="product_type">Product type</MenuItem>
                </Select>
            </FormControl>
        </div>

        <button
			style={{
				...Styles.addButton,
				...(isMobile && Styles.addButtonMobile)
			}}
			onClick={() => {
				setCurrentContentItem(null);
				setModalOpen(true);
			}}
        >
			Add
        </button>
    </div>

    {filteredContents.length === 0 ? (
        <div style={Styles.noDataContainer}>
            <div style={Styles.noDataText}>No content found for the selected filters</div>
        </div>
    ) : (
        // Render the filtered items
        filteredContents.map(item => (
            <div
                key={item.id}
                style={{
                    ...Styles.itemContainer,
                    ...(isMobile && Styles.itemContainerMobile)
                }}
            >
                <div style={{
                    ...Styles.itemContentContainer,
                    ...(isMobile && Styles.itemContentContainerMobile)
                }}>
                    <img
                        src={`/assets/languages/${item.language}.png`}
                        style={{
                            ...Styles.languageIcon
                        }}
                    />
                    <div style={Styles.typeTag}>
                        {item.type}
                    </div>
                    <div style={{
                        ...Styles.textContainer,
                        ...(isMobile && Styles.textContainerMobile)
                    }}>
                        <div style={Styles.itemTitle}>{item.title}</div>
                        <div style={Styles.itemDescription}>{item.description}</div>
                    </div>
                    <div style={Styles.buttonsContainer}>
                        <button
                            style={{
                                ...Styles.editButton,
                                ...(isMobile && Styles.editButtonMobile)
                            }}
                            onClick={() => {
                                setCurrentContentItem({
                                    id: item.id,
                                    type: item.type,
                                    title: item.title,
                                    description: item.description
                                });
                                setModalOpen(true);
                            }}
                        >
                            Edit
                        </button>
                        <button
                            style={{
                                ...Styles.deleteButton,
                                ...(isMobile && Styles.deleteButtonMobile)
                            }}
                            onClick={() => handleDelete(item.id)}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        ))
    )} 

    {/* Modal for adding/editing content */}
    <DynamicContentModal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        currentContentItem={currentContentItem}
        match={match}
        onSave={() => {
            onFetchDynamicContent(match.params.id);
        }}
    />
    </>
  );
}

const Styles = {
  // Base styles
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
	gap: 10
  },
  
  // Buttons container
  buttonsContainer: {
    display: 'flex',
    gap: 5
  },
  // Mobile styles
  headerContainerMobile: {
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  
  // Base styles
  selectorsContainer: {
    display: 'flex',
    alignItems: 'center',
	maxWidth: '300px',
	width: '100%',
    gap: 10
  },
  // Mobile styles
  selectorsContainerMobile: {
    maxWidth: '100%',
    marginBottom: 10
  },
  
  // Base styles
  addButton: {
    padding: '8px 16px',
    backgroundColor: '#f0f0f0',
    border: '1px solid #ddd',
    borderRadius: '4px',
    cursor: 'pointer'
  },
  // Mobile styles
  addButtonMobile: {
    width: '100%',
    marginTop: 10
  },
  
  // Item styles
  itemContainer: {
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '10px',
    marginBottom: 10,
	marginTop: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff'
  },
  // Mobile styles
  itemContainerMobile: {
    flexDirection: 'column',
    alignItems: 'stretch',
    gap: 10
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 1
  },
  noDataContainer: {
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '20px',
    marginTop: 15,
    backgroundColor: '#f9f9f9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noDataText: {
    fontSize: '16px',
    color: '#666',
    textAlign: 'center'
  },
  itemContentContainer: {
    display: 'flex',
    alignItems: 'center',
	flex: 1,
    gap: 10,
	width: '100%'
  },
  // Mobile styles
  itemContentContainerMobile: {
    flexWrap: 'wrap'
  },
  languageIcon: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    marginLeft: 5,
    cursor: 'pointer'
  },
  typeTag: {
    padding: '4px 10px',
    backgroundColor: '#f0f0f0',
    borderRadius: '12px',
    fontSize: '12px',
    color: '#555',
    display: 'inline-block',
    fontWeight: '500'
  },
  textContainer: {
    maxWidth: 300,
    flex: 1
  },
  // Mobile styles
  textContainerMobile: {
    marginTop: 5,
	marginLeft: 0,
	maxWidth: '100%'
  },
  itemTitle: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    fontSize: '16px'
  },
  itemDescription: {
    fontSize: '14px',
    color: '#666',
	whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
	marginTop: 3
  },
  editButton: {
    padding: '5px 10px',
    backgroundColor: '#f0f0f0',
	flex: 1,
    border: '1px solid #ddd',
    borderRadius: '4px',
    cursor: 'pointer'
  },
  // Mobile styles
  editButtonMobile: {
    width: '100%',
    padding: '8px 16px'
  },
  // Delete button styles
  deleteButton: {
    padding: '5px 10px',
    backgroundColor: '#ffebee',
    flex: 1,
    border: '1px solid #ffcdd2',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#c62828'
  },
  // Delete button mobile styles
  deleteButtonMobile: {
    width: '100%',
    padding: '8px 16px'
  }
}